<template>
  <table class="table-list">
    <tr class="table-list__row--zebra">
      <th v-for="(header, key) in headers" :key="key">{{ header }}</th>
    </tr>
    <tr v-for="(row, idx1) in rows" :data-entity-id="row[0]" :key="idx1">
      <td v-for="(data, idx2) in row" :style="getStyle(data)" :key="idx2">{{ getContent(data) }}</td>
    </tr>
  </table>
</template>

<script setup lang="ts">
import { StyleValue } from "vue";

export type RowData = string | { content: string; style: StyleValue };
defineProps<{ headers: string[]; rows: RowData[][] }>();

const getContent = (data: RowData) => (typeof data === "object" ? data.content : data);
const getStyle = (data: RowData) => (typeof data === "object" ? data.style : "");
</script>

<style lang="scss" scoped>
.table-list {
  @include table-styling;
  &__row--zebra {
    background: var(--black-10);
  }
}
</style>
